import { Link } from "gatsby";
import React from "react";
//import { cn } from "../lib/helpers";

const Footer = () => (
  <footer className="footer">
    <div className="footer__wrapper">
      <div className="footer__column">
        <div className="footer__nav">
          <span className="footer__title">Navigation</span>
          <ul className="footer__list">
            <Link className="footer__list-item" to="/">
              <li>Hjem</li>
            </Link>
            <Link className="footer__list-item" to="/coaching/">
              <li>Coaching</li>
            </Link>
            <Link className="footer__list-item" to="/priser/">
              <li>Priser</li>
            </Link>
            <Link className="footer__list-item" to="/om-oss/">
              <li>Om Oss</li>
            </Link>
            <Link className="footer__list-item" to="/blog/">
              <li>Blogg</li>
            </Link>
            <Link className="footer__list-item" to="/privacy-policy/">
              <li>Privatlivspolitikk</li>
            </Link>
          </ul>
        </div>
      </div>
      <div className="footer__column">
        <div className="footer__contact">
          <span className="footer__title">Kontakt oss</span>
          <span className="footer__contact-info">Email: pergunnaralfheim@gmail.com</span>
          <span className="footer__contact-info">Telefon: +47 99631173</span>
        </div>
        <span className="footer__copyright">
          Copyright &copy; 2020 PG Treningslab. Design and development by Tony Heimark
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;
