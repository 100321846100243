import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
//import { cn } from "../lib/helpers";

const Elevator = ({ title, image, info, extraInfo }) => (
  <div className="elevator">
    <div className="elevator__wrapper">
      <div className="elevator__img-wrapper">
        {image && image.asset && (
          <img
            className="elevator__img"
            src={imageUrlFor(buildImageObj(image))
              .width(600)
              .height(600)
              //.fit("crop")
              .auto("format")
              .url()}
            alt={image.alt}
          />
        )}
      </div>
      <div className="elevator__info">
        <h1 className="elevator__title">{title}</h1>
        <div className="elevator__ingress">{info && <PortableText blocks={info} />}</div>
      </div>
    </div>
    {extraInfo && (
      <div className="elevator__extra-info">
        <PortableText blocks={extraInfo} />
      </div>
    )}
  </div>
);

export default Elevator;
