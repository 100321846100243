import React from "react";
import Header from "./header";
import Footer from "./footer";
import redDotLeft from "../assets/reddot-left.svg";
import redDotRight from "../assets/reddot-right.svg";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className="content">
      <img
        className="content__background-blob content__background-blob--left"
        src={redDotLeft}
        alt="bakgrunnsbilde flekk"
      />
      <img
        className="content__background-blob content__background-blob--right"
        src={redDotRight}
        alt="bakgrunnsbilde flekk"
      />
      {children}
    </div>
    <Footer />
  </>
);

export default Layout;
