import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Icon from "./icon";
import Logo from "../assets/logo-v15-v2.png";
//import { cn } from "../lib/helpers";

const Header = ({ siteTitle }) => {
  const [showNav, setShowNav] = useState(false);
  let currentPath = [];

  if (typeof window !== `undefined`) {
    currentPath = window.location;
  }

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <div className={`header ${showNav ? "header--fixed" : ""}`}>
      <div className={`header__wrapper ${showNav ? "header__wrapper--fixed" : ""}`}>
        <div className={"header__branding"}>
          <Link to="/">
            <img className="header__logo" src={Logo} alt="logo" />
          </Link>
        </div>

        <button className="header__toggleNavButton" onClick={toggleNav}>
          <Icon symbol="hamburger" />
        </button>

        <nav
          className={`header__nav ${
            showNav
              ? "header__showNav header__showNav--open"
              : "header__showNav header__showNav--closed"
          }`}
        >
          <ul>
            <li>
              <Link
                className={`header__link ${
                  currentPath && currentPath.pathname && currentPath.pathname.includes("/coaching/")
                    ? "header__link--active"
                    : ""
                }`}
                to="/coaching/"
              >
                Coaching
              </Link>
            </li>
            <li>
              <Link
                className={`header__link ${
                  currentPath && currentPath.pathname && currentPath.pathname.includes("/om-oss/")
                    ? "header__link--active"
                    : ""
                }`}
                to="/om-oss/"
              >
                Om Oss
              </Link>
            </li>
            <li>
              <Link
                className={`header__link ${
                  currentPath && currentPath.pathname && currentPath.pathname.includes("/blog/")
                    ? "header__link--active"
                    : ""
                }`}
                to="/blog/"
              >
                Blogg
              </Link>
            </li>
            <li>
              <Link
                className={`header__link ${
                  currentPath && currentPath.pathname && currentPath.pathname.includes("/omtaler/")
                    ? "header__link--active"
                    : ""
                }`}
                to="/omtaler/"
              >
                Omtaler
              </Link>
            </li>
            <li>
              <Link
                className={`header__link ${
                  currentPath && currentPath.pathname && currentPath.pathname.includes("/priser/")
                    ? "header__link--active"
                    : ""
                }`}
                to="/priser/"
              >
                Priser
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
